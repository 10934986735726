/* * Silicon | Multipurpose Bootstrap 5 Template & UI Kit
 * Copyright 2022 Createx Studio
 * Theme styles */
// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'utilities';

// Bootstrap
@import '../vendor/bootstrap/scss/functions';
@import '../vendor/bootstrap/scss/variables';
@import '../vendor/bootstrap/scss/maps';
@import '../vendor/bootstrap/scss/mixins';
@import '../vendor/bootstrap/scss/utilities';
@import '../vendor/bootstrap/scss/root';
@import '../vendor/bootstrap/scss/reboot';
@import '../vendor/bootstrap/scss/type';
@import '../vendor/bootstrap/scss/images';
@import '../vendor/bootstrap/scss/containers';
@import '../vendor/bootstrap/scss/grid';
@import '../vendor/bootstrap/scss/tables';
@import '../vendor/bootstrap/scss/forms';
@import '../vendor/bootstrap/scss/buttons';
@import '../vendor/bootstrap/scss/transitions';
@import '../vendor/bootstrap/scss/dropdown';
@import '../vendor/bootstrap/scss/button-group';
@import '../vendor/bootstrap/scss/nav';
@import '../vendor/bootstrap/scss/navbar';
@import '../vendor/bootstrap/scss/card';
@import '../vendor/bootstrap/scss/accordion';
@import '../vendor/bootstrap/scss/breadcrumb';
@import '../vendor/bootstrap/scss/pagination';
@import '../vendor/bootstrap/scss/badge';
@import '../vendor/bootstrap/scss/alert';
@import '../vendor/bootstrap/scss/progress';
@import '../vendor/bootstrap/scss/close';
@import '../vendor/bootstrap/scss/toasts';
@import '../vendor/bootstrap/scss/modal';
@import '../vendor/bootstrap/scss/tooltip';
@import '../vendor/bootstrap/scss/popover';
@import '../vendor/bootstrap/scss/spinners';
@import '../vendor/bootstrap/scss/offcanvas';
@import '../vendor/bootstrap/scss/placeholders';
@import '../vendor/bootstrap/scss/helpers';
@import '../vendor/bootstrap/scss/utilities/api';

// Layout & componentss
@import 'reboot';
@import 'components';
@import 'prism';

// Dark mode
@import 'dark-mode';

// User custom styles
@import 'user';

@keyframes animated-background {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.text-gradient-primary {
    background: linear-gradient(45deg,  var(--si-code-color), var(--si-primary), #8B5CF6, #D946EF);
    background-size: 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0,0,0,0);
    animation: animated-background 15s ease infinite;
    width: 100%;
}

.price-list-item:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.cookie-consent {
    position: fixed;
    bottom: 8px;
    right: 20px;
    z-index: 120;
    cursor: pointer;
    border-radius: 3px;
}

